import { memo } from "react";
import classnames from "classnames";
import SignedImg from "components/SignedImg";
import tqiIcon from "assets/images/quantumFavNew.svg";
import dtiIcon from "assets/images/dtFavNew.svg";
import spaceIcon from "assets/images/spaceFavNew.svg";
import climateIcon from "assets/images/climateFavNew.svg";
import config from "config";

interface IEntityLogo {
  entityName: string;
  logoFromDB: string | null | undefined;
  logoWidth: number;
  classNames?: string;
}

const logoPlaceholder =
  config.VERTICAL === "METAVERSE"
    ? dtiIcon
    : config.VERTICAL === "SPACE"
    ? spaceIcon
    : config.VERTICAL === "CLIMATE"
    ? climateIcon
    : tqiIcon;

const EntityLogo = ({
  entityName,
  logoFromDB,
  logoWidth = 22,
  classNames,
}: IEntityLogo) => {
  const logoStyle = {
    maxWidth: logoWidth,
    minWidth: logoWidth,
    minHeight: logoWidth,
    maxHeight: logoWidth,
  };

  return (
    <div key={entityName} className="rounded-full">
      {logoFromDB ? (
        <SignedImg
          alt={entityName}
          className={classnames(classNames, "rounded-full")}
          style={logoStyle}
          src={logoFromDB}
          placeholder={logoPlaceholder}
        />
      ) : (
        <div
          style={logoStyle}
          className={classnames(
            classNames,
            "select-none border border-primary bg-[#FFFFFF] text-primaryBlue text-[12px] rounded-full flex content-center items-center justify-center"
          )}
        >
          {entityName?.trim().charAt(0).toLocaleUpperCase()}
        </div>
      )}
    </div>
  );
};

export default memo(EntityLogo);
